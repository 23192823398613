import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { reactBaseUrl } from '~/data/env';
//check the keys for plural languages https://jsfiddle.net/sm9wgLze
const detection = {
    order: ['localStorage', 'cookie', 'navigator', 'htmlTag'],
    //ToDo temporary, remove in future
    lookupCookie: 'site_language',
    lookupLocalStorage: 'i18nextLng',
    caches: ['localStorage'],
};
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    detection,
    saveMissing: false,
    fallbackLng: 'en',
    keySeparator: false,
    ns: 'translation',
    defaultNS: 'translation',
    load: 'languageOnly',
    interpolation: {
        escapeValue: false,
        prefix: '%{',
        suffix: '}',
    },
    backend: {
        allowMultiLoading: false,
        loadPath: (lng, namespace) => {
            return reactBaseUrl
                ? `${reactBaseUrl}/locales/${lng}/${namespace}.json`
                : `/locales/${lng}/${namespace}.json`;
        },
    },
    debug: false,
    initImmediate: false,
    compatibilityJSON: 'v3',
});
export default i18n;
