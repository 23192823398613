import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { EnterpriseCoreModule, LicenseManager } from '@ag-grid-enterprise/core';
import { MasterDetailModule } from '@ag-grid-enterprise/master-detail';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import '@draft-js-plugins/mention/lib/plugin.css';
import 'antlr4';
import 'draft-js/dist/Draft.css';
import 'react-virtualized/styles.css';
import { reactBaseUrl } from '~/data/env';
import '~n/lib/i18n/i18n';
import './ds';
import './styles';
import './ui-kit/atoms/Icon/icons'; // preload svg files for svg-sprite-loader
if (process.env.NODE_ENV !== 'test') {
    __webpack_public_path__ = `${reactBaseUrl}/`;
}
ModuleRegistry.registerModules([
    EnterpriseCoreModule,
    ClipboardModule,
    ClientSideRowModelModule,
    MenuModule,
    SideBarModule,
    MasterDetailModule,
    RowGroupingModule,
    ColumnsToolPanelModule
]);
LicenseManager.setLicenseKey('CompanyName=Fohlio Inc,LicensedApplication=Fohlio Front End,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=5,LicensedProductionInstancesCount=1,AssetReference=AG-038494,SupportServicesEnd=19_February_2024_[v2]_MTcwODMwMDgwMDAwMA==a2ae5abb1fbabdfe5074ce78793661e9');
