import 'reflect-metadata';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import i18next from 'i18next';
import { Loader } from '~/ui-kit/atoms/Loader'; // important to leave import as it is now (because if we import from atoms then we do not have translations inside folder)
import './globalImports';

const AppContainer = React.lazy(() => import('./AppContainer'));

Modal.defaultStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.3)',
  },
};

const container = document.getElementById('react-root');
const root = createRoot(container);

i18next.on('initialized', () => {
  root.render(
    <Suspense fallback={<Loader />}>
      <AppContainer />
    </Suspense>
  );
});

i18next.on('failedLoading', () => {
  console.error('Unable to load translations.');
});